import { apiSlice } from '../../../api/apiSlice';
import { EmployeeWorkflowOvertimeDetail } from '../../../../../@types/EmployeeWorkflow';

export const workflowOvertimeApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailWorkflowOvertime: builder.query<
      EmployeeWorkflowOvertimeDetail,
      any
    >({
      query: args => {
        const { employeeId, ...query } = args;
        return {
          url: `/employee/employees/${employeeId}/workflow/overtime`,
          params: query,
        };
      },
      providesTags: ['employeeOvertimeWorkflow'],
    }),
    createOvertimeWorkflow: builder.mutation<
      void,
      { id: string; payload: any }
    >({
      query: ({ id, payload }) => ({
        url: `/employee/employees/${id}/workflow/overtime`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['employeeOvertimeWorkflow'],
    }),
    updateOvertimeWorkflow: builder.mutation<
      void,
      { id: string; payload: any }
    >({
      query: ({ id, payload }) => ({
        url: `/employee/employees/${id}/workflow/overtime`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['employeeOvertimeWorkflow'],
    }),
    deleteOvertimeWorkflow: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/employee/employees/${id}/workflow/overtime`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeOvertimeWorkflow'],
    }),
  }),
});

export const {
  useGetDetailWorkflowOvertimeQuery,
  useCreateOvertimeWorkflowMutation,
  useDeleteOvertimeWorkflowMutation,
  useUpdateOvertimeWorkflowMutation,
} = workflowOvertimeApiSlice;
