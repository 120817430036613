import { Box, SxProps, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../components/atom';
import { ArrowLeft } from '../../components/atom/icon';
import { OtpForm } from '../../components/molecule';
import LOCAL_STORAGE_KEY from '../../constants/localStorage';
import {
  useLoginMutation,
  useResendOtpMutation,
} from '../../store/feature/service/authApiSlice';
import { deleteCookie, getCookie, getTld } from '../../utils/cookies';
import { mappingError } from '../../utils/errorMapping';

const Otp = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { control, setError, watch } = useForm<any>({
    // resolver: yupResolver(login),
    reValidateMode: 'onChange',
    defaultValues: {
      otp: '',
    },
  });

  const [login, { isLoading }] = useLoginMutation();

  const endTime = React.useMemo(
    () => localStorage.getItem('otp__expiry_date'),
    [localStorage.getItem('otp__expiry_date')]
  );
  const [timer, setTimer] = useState(() => {
    const time = endTime as any;
    return Math.floor(+time - new Date().valueOf() / 1000);
  });
  const [isTimer, setIsTimer] = useState(true);
  const [debounchOtpData, setDebounchOtpData] = useState('');
  const [resendOtp] = useResendOtpMutation();

  useEffect(() => {
    const timeoutData = setTimeout(() => {
      setDebounchOtpData(watch('otp'));
    }, 500);
    return () => clearTimeout(timeoutData);
  }, [watch('otp')]);

  useEffect(() => {
    if (debounchOtpData && debounchOtpData.length === 6) {
      submit();
    }
  }, [debounchOtpData]);

  useEffect(() => {
    let timeoutReset: any = '';
    timeoutReset = setInterval(() => {
      const time = endTime as any;
      const difference = Math.floor(+time - new Date().valueOf() / 1000);
      setTimer(difference);
      if (difference < 1) {
        setIsTimer(!isTimer);
      }
    }, 1000);
    return () => {
      clearInterval(timeoutReset);
    };
  }, [endTime]);

  const style: SxProps = {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '24px',
    '& .container': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '24px',
    },
    '& .container .header': {
      position: 'relative',
      top: 0,
      left: 0,
      width: '100%',
      '& .back-button': {
        width: '24px',
        height: '24px',
        minWidth: '24px',
        padding: 0,
        paddingTop: '8px',
        background: '#fff',
        '& svg': {
          minWidth: '14px',
          minHeight: '14px',
          height: '14px',
          width: '14px',
          '& path': {
            stroke: '#101B28',
            strokeWidth: '2',
          },
        },
        '&:hover': {
          background: '#fff',
        },
      },
    },
  };

  const onResendOtp = async () => {
    try {
      await resendOtp({
        otpToken: getCookie('app__token__otp'),
      });
      setIsTimer(!isTimer);
      const expired = Math.floor(
        new Date(Number(new Date()) + 1 * 60 * 1000).valueOf() / 1000
      );

      localStorage.setItem('otp__expiry_date', JSON.stringify(expired));
    } catch (error) {
      console.log('error ->', error);
    }
  };

  const submit = async () => {
    try {
      const tld = getTld(window.location.hostname);
      const result = await login({
        otp: debounchOtpData,
        otpToken: getCookie('app__token__otp'),
      }).unwrap();
      const tenant = result?.tenants?.[0]?.subdomain;
      setTimeout(() => {
        if (tenant) {
          window.location.href = result.isAdmin
            ? `${window.location.protocol}//${tenant}.${window.location.host}`
            : `${window.location.protocol}//${tenant}.${window.location.host}/ess/employee`;
          deleteCookie('app__token__otp', '/', `.${tld}`);
          localStorage.removeItem('otp__expiry_date');
          return;
        }
        navigate('/');
        deleteCookie('app__token__otp', '/', `.${tld}`);
        localStorage.removeItem('otp__expiry_date');
      }, 500);
    } catch (error) {
      const err = error as any;
      mappingError(err.data.errors, setError, 'otp');
    }
  };

  return (
    <Box sx={style}>
      <Box className="container">
        <Box className="header">
          <Typography
            variant="h6"
            component="h6"
            align="center"
            fontWeight={700}
            position="relative"
          >
            Verification Code
          </Typography>
          <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              fullWidth={false}
              className="back-button"
            >
              <ArrowLeft />
            </Button>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="body14"
            color={palette.gray[90]}
            component="p"
            align="center"
          >
            Enter the 6-digit code we sent to
          </Typography>
          <Typography
            variant="body14"
            color={palette.gray[90]}
            fontWeight="600"
            component="p"
            align="center"
          >
            {localStorage.getItem(LOCAL_STORAGE_KEY.USER_EMAIL)}
          </Typography>
        </Box>
        <OtpForm disabled={isLoading} name="otp" control={control} />
        {timer > 0 && (
          <Typography
            variant="body14"
            color={palette.gray[90]}
            component="p"
            align="center"
          >
            Please wait within{' '}
            <Typography
              variant="body14"
              color={palette.gray[90]}
              component="span"
              fontWeight={600}
            >
              {timer} seconds
            </Typography>{' '}
            to resend
          </Typography>
        )}
        {timer <= 0 && !isTimer && (
          <Typography
            variant="body14"
            color={palette.gray[90]}
            component="p"
            align="center"
          >
            Didn’t receive a code?{' '}
            <Typography
              variant="body14"
              color={palette.blue[50]}
              component="span"
              display="inline-block"
              fontWeight={600}
              onClick={onResendOtp}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              Resend
            </Typography>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Otp;
