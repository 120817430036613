import { createSlice } from '@reduxjs/toolkit';
import { workflowTimeOffApiSlice } from './service/employee/workflow/timeOff';
import { workflowAttendanceApiSlice } from './service/employee/workflow/attendance';
import { workflowShiftApiSlice } from './service/employee/workflow/shift';
import { workflowOvertimeApiSlice } from './service/employee/workflow/overtime';
import { workflowDataUpdateApiSlice } from './service/employee/workflow/dataUpdate';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const employeeWorkflowSlice = createSlice({
  name: 'employeeWorkflow',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      workflowTimeOffApiSlice.endpoints.createTimeOffWorkflow.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow created.';
      }
    );
    builder.addMatcher(
      workflowTimeOffApiSlice.endpoints.createTimeOffWorkflow.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowTimeOffApiSlice.endpoints.updateTimeOffWorkflow.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow updated.';
      }
    );
    builder.addMatcher(
      workflowTimeOffApiSlice.endpoints.updateTimeOffWorkflow.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowTimeOffApiSlice.endpoints.deleteTimeOffWorkflow.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow deleted.';
      }
    );
    builder.addMatcher(
      workflowTimeOffApiSlice.endpoints.deleteTimeOffWorkflow.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowAttendanceApiSlice.endpoints.createAttendanceWorkflow
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow created.';
      }
    );
    builder.addMatcher(
      workflowAttendanceApiSlice.endpoints.createAttendanceWorkflow
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowAttendanceApiSlice.endpoints.updateAttendanceWorkflow
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow updated.';
      }
    );
    builder.addMatcher(
      workflowAttendanceApiSlice.endpoints.updateAttendanceWorkflow
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowAttendanceApiSlice.endpoints.deleteAttendanceWorkflow
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow deleted.';
      }
    );
    builder.addMatcher(
      workflowAttendanceApiSlice.endpoints.deleteAttendanceWorkflow
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowShiftApiSlice.endpoints.createShiftWorkflow.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow created.';
      }
    );
    builder.addMatcher(
      workflowShiftApiSlice.endpoints.createShiftWorkflow.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowShiftApiSlice.endpoints.updateShiftWorkflow.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow updated.';
      }
    );
    builder.addMatcher(
      workflowShiftApiSlice.endpoints.updateShiftWorkflow.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowShiftApiSlice.endpoints.deleteShiftWorkflow.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow deleted.';
      }
    );
    builder.addMatcher(
      workflowShiftApiSlice.endpoints.deleteShiftWorkflow.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowOvertimeApiSlice.endpoints.createOvertimeWorkflow.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow created.';
      }
    );
    builder.addMatcher(
      workflowOvertimeApiSlice.endpoints.createOvertimeWorkflow.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowOvertimeApiSlice.endpoints.updateOvertimeWorkflow.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow updated.';
      }
    );
    builder.addMatcher(
      workflowOvertimeApiSlice.endpoints.updateOvertimeWorkflow.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowOvertimeApiSlice.endpoints.deleteOvertimeWorkflow.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow deleted.';
      }
    );
    builder.addMatcher(
      workflowOvertimeApiSlice.endpoints.deleteOvertimeWorkflow.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowDataUpdateApiSlice.endpoints.createDataUpdateWorkflow
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow created.';
      }
    );
    builder.addMatcher(
      workflowDataUpdateApiSlice.endpoints.createDataUpdateWorkflow
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowDataUpdateApiSlice.endpoints.updateDataUpdateWorkflow
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow updated.';
      }
    );
    builder.addMatcher(
      workflowDataUpdateApiSlice.endpoints.updateDataUpdateWorkflow
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      workflowDataUpdateApiSlice.endpoints.deleteDataUpdateWorkflow
        .matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Workflow deleted.';
      }
    );
    builder.addMatcher(
      workflowDataUpdateApiSlice.endpoints.deleteDataUpdateWorkflow
        .matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
  },
});

export const { removeMessage } = employeeWorkflowSlice.actions;
export const employeeWorkflowReducer = employeeWorkflowSlice.reducer;
