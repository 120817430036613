import { apiSlice } from '../../../api/apiSlice';
import { EmployeeWorkflowDetail } from '../../../../../@types/EmployeeWorkflow';

export const workflowShiftApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailWorkflowShift: builder.query<EmployeeWorkflowDetail, any>({
      query: args => {
        const { employeeId, ...query } = args;
        return {
          url: `/employee/employees/${employeeId}/workflow/change-shift`,
          params: query,
        };
      },
      providesTags: ['employeeShiftWorkflow'],
    }),
    createShiftWorkflow: builder.mutation<void, { id: string; payload: any }>({
      query: ({ id, payload }) => ({
        url: `/employee/employees/${id}/workflow/change-shift`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['employeeShiftWorkflow'],
    }),
    updateShiftWorkflow: builder.mutation<void, { id: string; payload: any }>({
      query: ({ id, payload }) => ({
        url: `/employee/employees/${id}/workflow/change-shift`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['employeeShiftWorkflow'],
    }),
    deleteShiftWorkflow: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/employee/employees/${id}/workflow/change-shift`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeShiftWorkflow'],
    }),
  }),
});

export const {
  useGetDetailWorkflowShiftQuery,
  useCreateShiftWorkflowMutation,
  useUpdateShiftWorkflowMutation,
  useDeleteShiftWorkflowMutation,
} = workflowShiftApiSlice;
