import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';

type TimeOff = {
  id: string;
  name: string;
  typeName: string;
  code: string;
  entitlement: string;
};

type ListTimeOff = {
  data: TimeOff[];
  limit: number;
  page: number;
  total: number;
};

export const timeOffApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    listTimeOff: builder.query<ListTimeOff, any>({
      query: params => {
        const { search, page, limit, searchString } = params;
        return {
          url: '/time-management/time-off',
          params: { search, page, limit, ...searchString },
        };
      },
      providesTags: ['listTimeOff'],
    }),
    createTimeOff: builder.mutation<any, any>({
      query: payload => ({
        url: '/time-management/time-off',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['listTimeOff'],
    }),
    detailsTimeOff: builder.query<any, any>({
      query: id => ({
        url: `/time-management/time-off/${id}`,
      }),
      providesTags: ['detailTimeOff'],
    }),
    listEntitlementTimeOffDetail: builder.query<
      any,
      Omit<QueryParams, 'sort'> & { sortBy: string; id: string }
    >({
      query: params => {
        const { search, page, sortType, sortBy, limit, searchString, id } =
          params;
        return {
          url: `/time-management/time-off/${id}/entitlements`,
          params: { search, page, sortType, sortBy, limit, ...searchString },
        };
      },
    }),
    assignTimeOffTemporary: builder.mutation<any, any>({
      query: ({ sessionId, payload }) => ({
        url: `/time-management/time-off/assign-employees/${sessionId}/temp`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        'timeOffAssignedEmployeesTemp',
        'initialTimeOffEmployees',
      ],
    }),
    submitTimeOffAssignment: builder.mutation<any, any>({
      query: ({ sessionId, payload }) => ({
        url: `/time-management/time-off/assign-employees/${sessionId}`,
        method: 'POST',
        body: payload,
      }),
    }),
    getInitialTimeOffEmployees: builder.query<any, any>({
      query: params => {
        const {
          search,
          page,
          limit,
          sortType,
          sort,
          searchString,
          type,
          sessionId,
          timeOffId,
        } = params;
        return {
          url: '/time-management/time-off/employees',
          params: {
            search,
            page,
            limit,
            sortType,
            sort,
            type,
            sessionId,
            timeOffId,
            ...searchString,
          },
        };
      },
      providesTags: ['initialTimeOffEmployees'],
    }),
    timeOffAssignedEmployeesTemporary: builder.query<any, any>({
      query: params => {
        const {
          search,
          page,
          limit,
          sortType,
          sort,
          searchString,
          sessionId,
          timeOffId,
        } = params;
        return {
          url: '/time-management/time-off/assign-employees',
          params: {
            search,
            page,
            limit,
            sortType,
            sort,
            sessionId,
            timeOffId,
            ...searchString,
          },
        };
      },
      providesTags: ['timeOffAssignedEmployeesTemp'],
    }),
  }),
});

export const {
  useListTimeOffQuery,
  useCreateTimeOffMutation,
  useDetailsTimeOffQuery,
  useListEntitlementTimeOffDetailQuery,
  useAssignTimeOffTemporaryMutation,
  useSubmitTimeOffAssignmentMutation,
  useGetInitialTimeOffEmployeesQuery,
  useTimeOffAssignedEmployeesTemporaryQuery,
} = timeOffApiSlice;
