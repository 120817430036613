import { PayrollRunStatus } from '../@types/PayrollRun/List';
import { OneTimePaymentStatus } from '../@types/PayrollRun/OneTimePaymentType';

const defaultFilterValue = {
  payTypeCode: [],
  legalEntityId: [],
  payrollExpenseCurrencyCode: [],
  payrollExpenseFrom: '',
  payrollExpenseTo: '',
  totalTaxesCurrencyCode: [],
  totalTaxesFrom: '',
  totalTaxesTo: '',
  statusCode: [],
};

const options = {
  payType: [
    {
      label: 'Regular',
      value: 'REGULAR',
    },
    {
      label: 'Irregular',
      value: 'IRREGULAR',
    },
  ],
  payItemType: [
    {
      label: 'Recurring',
      value: 'RECURRING',
    },
    {
      label: 'Non Recurring',
      value: 'NON_RECURRING',
    },
  ],
  statusCode: [
    {
      label: 'Manage',
      value: 'MANAGE',
    },
    {
      label: 'Review',
      value: 'REVIEW',
    },
    {
      label: 'Complete',
      value: 'COMPLETE',
    },
    {
      label: 'Lock',
      value: 'LOCKED',
    },
    {
      label: 'Closed',
      value: 'CLOSED',
    },
  ],
  primaryPaymentMethodCode: [
    {
      label: 'Bank Transfer',
      value: 'BANK_TRANSFER',
    },
    {
      label: 'Cash',
      value: 'CASH',
    },
    {
      label: 'Cheque',
      value: 'CHEQUE',
    },
  ],
  taxCalculationMethod: [
    {
      label: 'Nett',
      value: 'NETT',
    },
    {
      label: 'Gross',
      value: 'GROSS',
    },
    {
      label: 'Gross Up',
      value: 'GROSS UP',
    },
    {
      label: 'Mix',
      value: 'MIX',
    },
  ],
};

const headerKey = {
  periodName: 'Period',
  payTypeName: 'Pay Type',
  payFrequencyName: 'Pay Frequency',
  pic: 'PIC',
  payDate: 'Pay Date',
  legalEntityName: 'Legal Entity',
  totalEmployee: 'Total Employee',
  payrollExpenseCurrencyCode: 'Payroll Expenses Currency',
  totalPayrollExpenses: 'Total Payroll Expenses',
  taxesCurrencyCode: 'Taxes Currency',
  totalTaxes: 'Total Taxes',
  statusName: 'Status',
};

const alterHeaderKey = {
  'Pay Type': 'Type',
  'Pay Frequency': 'Frequency',
  'Payroll Expenses Currency': 'Currency',
  'Total Payroll Expenses': 'PAYROLL EXPENSES',
  'Taxes Currency': 'Currency',
};

const stepperRunPayroll = [
  'SETUP',
  'MANAGE',
  'REVIEW',
  'COMPLETE',
] as PayrollRunStatus[];

const stepperOneTimePayment = ['Basic Info', 'Payment'];
const listVariantOneTimePayment = ['Basic Info', 'Payment'];

const excludePayrollStepper = [
  'LOCK',
  'CLOSED',
  'UNFINALIZE',
] as PayrollRunStatus[];

const listVariant = [
  'SETUP',
  'MANAGE',
  'REVIEW',
  'COMPLETE',
  'LOCK',
  'CLOSED',
  'UNFINALIZE',
] as PayrollRunStatus[];

const payrollRunStep1DefaultValue = {
  payGroup: null,
  payDate: '',
  pic: [],
};

const payItemHeader = {
  name: 'Name',
  code: 'Code',
  groupName: 'Group',
  taxCalculationName: 'Tax Calculation',
  action: 'Action',
};

const employeeSectionHeader = {
  employeeNumber: 'Employee Id',
  fullName: 'Name',
  totalEarningCurrencyCode: 'Currency Earning',
  totalEarning: 'Earning',
  totalDeductionCurrencyCode: 'Currency Deduction',
  totalDeduction: 'Deductions',
  employeeTaxesCurrencyCode: 'Currency Employee Taxes',
  employeeTaxes: 'Employee Taxes',
  netPayCurrencyCode: 'Currency Net Pay',
  netPay: 'Net Pay',
  // variantPercentage: '(%)',
  jobPositionName: 'Job Position',
  jobGradeName: 'Job Grade',
  organizationName: 'Organization',
  employmentTypeName: 'Employment Type',
  employmentStatusName: 'Employment Status',
  joinDate: 'Join Date',
  endDate: 'End Date',
  citizenshipName: 'Citizenship',
  action: 'Action',
  yearsOfService: 'Years of Service',
  lastPaymentDate: 'Last Payment Date',
  effectiveTerminationDate: 'Effective Termination Date',
};

const employeePaymentDetail = {
  payItemName: 'Pay Item',
  payItemCode: 'Code',
  groupName: 'Group',
  currentCurrencyCode: 'Currency',
  currentAmount: 'Amount',
};

const expensesBreakdownHeader = {
  payItemName: 'Pay Item',
  payItemCode: 'Code',
  payItemGroup: 'Group',
  currencyCode: 'Currency Amount',
  amount: 'Amount',
};

const tabDetailRunPayrollStepManage = {
  employeeNumber: 'Employee Id',
  fullName: 'Name',
  // legalEntityName: 'Legal Entity',
  organizationName: 'Organization',
  jobPositionName: 'Job Position',
  jobGradeName: 'Job Grade',
  employmentTypeName: 'Employment Type',
  employmentStatusName: 'Employment Status',
  // severity: 'Severity',
  joinDate: 'Join Date',
  endDate: 'End Date',
  citizenshipName: 'Citizenship',
  // action: 'Action',
  lastPaymentDate: 'Last Payment Date',
  effectiveTerminationDate: 'Effective Termination Date',
};

const tabDetailRunPayrollStepReview = {
  employeeNumber: 'Employee Id',
  fullName: 'Name',
  totalEarningCurrencyCode: 'Currency Earning',
  totalEarning: 'Earnings',
  totalDeductionCurrencyCode: 'Currency Deduction',
  totalDeduction: 'Deductions',
  organizationName: 'Organization',
  jobPositionName: 'Job Position',
  jobGradeName: 'Job Grade',
  employmentTypeName: 'Employment Type',
  employmentStatusName: 'Employment Status',
  joinDate: 'Join Date',
  endDate: 'End Date',
  citizenshipName: 'Citizenship',
  action: 'Action',
  yearsOfService: 'Years of Service',
  lastPaymentDate: 'Last Payment Date',
  effectiveTerminationDate: 'Effective Termination Date',
};

const setupPayItem = {
  name: 'Name',
  code: 'Code',
  groupName: 'Group',
  taxCalculationName: 'Tax Calculation',
};

const defaultEmployeeStepReviewFilterValue = {
  earningCurrencyCode: [],
  earningAmountFrom: '',
  earningAmountTo: '',
  deductionCurrencyCode: [],
  deductionAmountFrom: '',
  deductionAmountTo: '',
  primaryPaymentMethodCode: [],
  primaryBankId: [],
  taxStatusId: [],
  taxCalculationMethodCode: [],
  legalEntityId: [],
  organizationId: [],
  jobPositionId: [],
  jobGradeId: [],
  employmentTypeId: [],
  employmentStatusId: [],
  joinDateFrom: '',
  joinDateTo: '',
  endDateFrom: '',
  endDateTo: '',
  citizenshipId: [],
};

const defaultEmployeeStepCompleteFilterValue = {
  earningCurrencyCode: [],
  earningAmountFrom: '',
  earningAmountTo: '',
  deductionCurrencyCode: [],
  deductionAmountFrom: '',
  deductionAmountTo: '',
  employerTaxesCurrencyCode: [],
  employerTaxesFrom: '',
  employerTaxesTo: '',
  netPayCurrencyCode: [],
  netPayFrom: '',
  netPayTo: '',
  variantPercentageFrom: '',
  variantPercentageTo: '',
  primaryPaymentMethodCode: [],
  primaryBankId: [],
  taxStatusId: [],
  taxCalculationMethodCode: [],
  legalEntityId: [],
  organizationId: [],
  jobPositionId: [],
  jobGradeId: [],
  employmentTypeId: [],
  employmentStatusId: [],
  joinDateFrom: '',
  joinDateTo: '',
  endDateFrom: '',
  endDateTo: '',
  citizenshipId: [],
};

const defaultExpensesBreakdownStepCompleteFilterValue = {
  groupId: [],
  // typeCode: [],
  // totalAmountCurrencyCode: [],
  // totalAmountFrom: '',
  // totalAmountTo: '',
};

const defaultPaymentDetailsFilterValue = {
  previousCurrencyCode: [],
  previousAmountFrom: '',
  previousAmountTo: '',
  currentCurrencyCode: [],
  currentAmountFrom: '',
  currentAmountTo: '',
  variantPercentageFrom: '',
  variantPercentageTo: '',
};

const defaultPaymentValue = {
  payGroup: null,
  employee: null,
  currency: null,
  amountTypeCode: 'FIXED',
  overrideCode: 'YES',
  adjustmentTypeCode: 'ADDITION',
  payItem: null,
  remarks: '',
  taxCalculation: '',
  amount: '',
};

const paginationKey = {
  employeeReview: 'employee-step-review',
  employeeComplete: 'employee-step-complete',
  expenseComplete: 'expense-breakdown-step-complete',
  paymentDetailStepReview: 'payment-detail-step-review',
};

const defaultOTPaymentValue = {
  employee: null,
  payPeriod: null,
  payItem: null,
  amountTypeCode: 'FIXED',
  overrideCode: 'YES',
  adjustmentTypeCode: 'ADDITION',
  currency: null,
  amount: '',
  remarks: '',
};

const oneTimePaymentHeader = {
  oneTimePaymentNumber: 'ID',
  employeeNumber: 'Employee ID',
  employeeName: 'Employee',
  payPeriodName: 'Pay Period',
  payItemName: 'Pay Item',
  amountTypeName: 'Amount Type',
  currencyCode: 'Currency',
  amount: 'Amount',
};

export default {
  defaultFilterValue,
  options,
  headerKey,
  alterHeaderKey,
  stepperRunPayroll,
  listVariant,
  excludePayrollStepper,
  payrollRunStep1DefaultValue,
  payItemHeader,
  employeeSectionHeader,
  expensesBreakdownHeader,
  employeePaymentDetail,
  setupPayItem,
  tabDetailRunPayrollStepManage,
  tabDetailRunPayrollStepReview,
  defaultEmployeeStepReviewFilterValue,
  defaultEmployeeStepCompleteFilterValue,
  defaultExpensesBreakdownStepCompleteFilterValue,
  defaultPaymentDetailsFilterValue,
  paginationKey,
  defaultPaymentValue,
  oneTimePaymentHeader,
  stepperOneTimePayment,
  listVariantOneTimePayment,
  defaultOTPaymentValue,
};
