import React from 'react';
import { RouteNode } from '../../@types';

const CreateTimeOff = React.lazy(
  () => import('../../pages/employee/workFlow/timeOff/Create')
);
const UpdateTimeOff = React.lazy(
  () => import('../../pages/employee/workFlow/timeOff/Correction')
);
const CreateAttendance = React.lazy(
  () => import('../../pages/employee/workFlow/attendance/Create')
);
const UpdateAttendance = React.lazy(
  () => import('../../pages/employee/workFlow/attendance/Update')
);
const CreateShift = React.lazy(
  () => import('../../pages/employee/workFlow/shift/Create')
);
const UpdateShift = React.lazy(
  () => import('../../pages/employee/workFlow/shift/Update')
);
const CreateOvertime = React.lazy(
  () => import('../../pages/employee/workFlow/overtime/Create')
);
const UpdateOvertime = React.lazy(
  () => import('../../pages/employee/workFlow/overtime/Update')
);
const CreateDataUpdate = React.lazy(
  () => import('../../pages/employee/workFlow/dataUpdate/Create')
);
const UpdateDataUpdate = React.lazy(
  () => import('../../pages/employee/workFlow/dataUpdate/Update')
);

const employeeWorkflow: RouteNode[] = [
  {
    label: '',
    path: 'employee/detail/:id/workflow/time-off/create',
    component: CreateTimeOff,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/workflow/time-off/update',
    component: UpdateTimeOff,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/workflow/attendance/create',
    component: CreateAttendance,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/workflow/attendance/update',
    component: UpdateAttendance,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/workflow/shift/create',
    component: CreateShift,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/workflow/shift/update',
    component: UpdateShift,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/workflow/overtime/create',
    component: CreateOvertime,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/workflow/overtime/update',
    component: UpdateOvertime,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/workflow/data-update/create',
    component: CreateDataUpdate,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/workflow/data-update/update',
    component: UpdateDataUpdate,
    child: null,
    requiredPermissions: 'UPDATE',
  },
];

export default employeeWorkflow;
