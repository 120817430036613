import { CutOffDataType } from '../../../@types/PayGroup';
import { PayrollRunListType } from '../../../@types/PayrollRun/List';
import { SubmitRunPayrollStepSetup } from '../../../@types/PayrollRun/StepSetup/StepSetup';
import { QueryParams } from '../../../@types/QueryParams';
import { apiSlice } from '../api/apiSlice';

interface TabQuery extends QueryParams {
  payGroupId: string;
  payrollRunId: string;
  payGroupLabel: string;
}

export const PayrollRunApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPayrollRunList: builder.query<PayrollRunListType, QueryParams>({
      query: params => {
        const { search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/payroll-run`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['payrollRunList'],
    }),
    submitStepSetup: builder.mutation<void, SubmitRunPayrollStepSetup>({
      query: data => {
        const { id, ...props } = data;
        return {
          url: `/payroll/payroll-run/${id}/setup`,
          method: 'POST',
          body: props,
        };
      },
      invalidatesTags: ['payrollRunList'],
    }),
    getDetailStatus: builder.query<any, any>({
      query: params => {
        const { id } = params;
        return `/payroll/payroll-run/${id}/status`;
      },
      providesTags: ['payrollDetailStatus'],
    }),
    getApprovals: builder.query<any, any>({
      query: params => {
        const { id } = params;
        return `/payroll/payroll-run/${id}/approvals`;
      },
    }),
    updateDetailStatus: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/payroll/payroll-run/${id}/change-status`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        'payrollDetailStatus',
        'payrollRunSummary',
        'payrollRunList',
        'PaymentDetail',
        'employeeListPayrollRun',
      ],
    }),
    payrollSyncPaygroup: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/payroll/payroll-run/${id}/sync-pay-group`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['expenseListPayrollRun'],
    }),
    getCutOff: builder.query<
      CutOffDataType,
      { id: string; payGroupLabel: string }
    >({
      query: params => {
        const { id, payGroupLabel } = params;
        return {
          url: `/payroll/pay-groups/${id}/info/cut-off`,
          params: {
            payGroupLabel,
          },
        };
      },
    }),
    getPayrollStepManageEmployees: builder.query<any, QueryParams>({
      query: params => {
        const { id, search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/payroll-run/${id}/manage/employees`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['ListEmployeeSetupManage'],
    }),
    getPayrollStepManageSeverityCount: builder.query<any, any>({
      query: params => {
        const { id } = params;
        return {
          url: `/payroll/payroll-run/${id}/manage/severity`,
        };
      },
      providesTags: ['DetailSeverity'],
    }),
    getPayrollPaymentDetail: builder.query<any, any>({
      query: params => {
        const {
          id,
          employeeId,
          search,
          page,
          sortType,
          sort,
          type,
          searchString,
        } = params;
        return {
          url: `/payroll/payroll-run/${id}/review/payment-detail/${employeeId}`,
          params: { search, page, sortType, sort, type, ...searchString },
        };
      },
      providesTags: ['PaymentDetail'],
    }),
    getPayrollStepReviewEmployees: builder.query<any, any>({
      query: params => {
        const { id, search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/payroll-run/${id}/review/employees`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
    }),
    getPayrollStepReviewGrossPay: builder.query<any, any>({
      query: params => {
        const { id } = params;
        return {
          url: `/payroll/payroll-run/${id}/review/gross-pay`,
        };
      },
    }),
    getPayrollRunSummary: builder.query<any, any>({
      query: params => {
        const { id } = params;
        return {
          url: `/payroll/payroll-run/${id}/summary`,
        };
      },
      providesTags: ['payrollRunSummary'],
    }),
    getPayrollRunEmployeePaymentsTab: builder.query<any, any>({
      query: params => {
        const { id, search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/payroll-run/${id}/summary/employees`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['employeeListPayrollRun'],
    }),
    getPayrollRunExpenseBreakdown: builder.query<any, any>({
      query: params => {
        const { id, search, page, sortType, sort, searchString } = params;
        return {
          url: `/payroll/payroll-run/${id}/summary/expenses-breakdown`,
          params: { search, page, sortType, sort, ...searchString },
        };
      },
      providesTags: ['expenseListPayrollRun'],
    }),
    getPayrollRunStepSetupHeader: builder.query<any, any>({
      query: params => {
        const { id } = params;
        return {
          url: `/payroll/payroll-run/${id}/setup/header`,
        };
      },
    }),
    getPayItemStepSetup: builder.query<PayrollRunListType, TabQuery>({
      query: params => {
        const { payrollRunId, ...query } = params;
        return {
          url: `/payroll/payroll-run/${payrollRunId}/setup/pay-items`,
          params: query,
        };
      },
      providesTags: ['payItemStepSetup'],
    }),
    cancelDetailStatus: builder.mutation({
      query: ({ id }) => ({
        url: `/payroll/payroll-run/${id}/cancel-calculation`,
        method: 'POST',
      }),
      invalidatesTags: ['payrollDetailStatus'],
    }),
    getPaymentDetailSummary: builder.query({
      query: ({ id, employeeId, payitemId }) =>
        `/payroll/payroll-run/${id}/review/payment-detail/${employeeId}/summary/${payitemId}`,
    }),
    payrollStepSetupAddPayItem: builder.mutation({
      query: data => {
        const { id, ...body } = data;
        return {
          url: `/payroll/payroll-run/${id}/setup/pay-items`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['payItemStepSetup'],
    }),
    payrollStepSetupRemovePayItem: builder.mutation({
      query: data => {
        const { id, payItemId, ...query } = data;
        return {
          url: `/payroll/payroll-run/${id}/setup/pay-items/${payItemId}`,
          method: 'DELETE',
          params: query,
        };
      },
      invalidatesTags: ['payItemStepSetup'],
    }),
    payrollStepManageAddEmployee: builder.mutation({
      query: data => {
        const { id, ...body } = data;
        return {
          url: `/payroll/payroll-run/${id}/manage/employees`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['ListEmployeeSetupManage', 'DetailSeverity'],
    }),
    payrollStepManageDeleteEmployee: builder.mutation({
      query: ({ id, employeeId }) => ({
        url: `/payroll/payroll-run/${id}/manage/employees/${employeeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ListEmployeeSetupManage', 'DetailSeverity'],
    }),
    payrollRunExportTransactionDetailMatrix: builder.query({
      query: id => `/payroll/payroll-run/${id}/transaction-detail-matrix`,
    }),
    payrollRunExportPayslipForEmployeer: builder.query({
      query: id => `/payroll/payroll-run/${id}/payslip/employeer`,
    }),
    getPaymentInfo: builder.query<any, any>({
      query: params => {
        const { id, employeeId } = params;
        return {
          url: `/payroll/payroll-run/${id}/payment-detail/${employeeId}/info`,
        };
      },
    }),
    createPayment: builder.mutation<void, any>({
      query: data => {
        const { id, employeeId, body } = data;
        return {
          url: `/payroll/payroll-run/${id}/payment-detail/${employeeId}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [
        'ListEmployeeSetupManage',
        'PaymentDetail',
        'employeeListPayrollRun',
        'expenseListPayrollRun',
      ],
    }),
    recalculatePayroll: builder.mutation<any, any>({
      query: ({ payload, id }) => ({
        url: `/payroll/payroll-run/${id}/recalculate`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        'payrollRunSummary',
        'expenseListPayrollRun',
        'employeeListPayrollRun',
        'PaymentDetail',
      ],
    }),
    rollbackPayrollRun: builder.mutation<any, any>({
      query: id => ({
        url: `/payroll/payroll-run/${id}/rollback`,
        method: 'POST',
      }),
      invalidatesTags: [
        'payrollRunSummary',
        'payrollDetailStatus',
        'payrollRunList',
      ],
    }),
    reopenPayrollRun: builder.mutation<any, any>({
      query: id => ({
        url: `/payroll/payroll-run/${id}/reopen`,
        method: 'POST',
      }),
      invalidatesTags: [
        'payrollRunSummary',
        'payrollDetailStatus',
        'payrollRunList',
      ],
    }),
    validatePassword: builder.mutation<any, any>({
      query: payload => ({
        url: '/identity/authenticate/validate-password',
        method: 'POST',
        body: payload,
      }),
    }),
    getWorkflowFindDetail: builder.query<any, any>({
      query: params => ({
        url: `/security/workflows/find-detail`,
        params,
      }),
    }),
    createWorkflowOverview: builder.mutation<any, any>({
      query: payload => ({
        query: '/security/workflow-overview',
        method: 'POST',
        body: payload,
      }),
    }),
    compilePayrollEmployee: builder.mutation<
      any,
      { body: { token: any }; payrollID: string | undefined }
    >({
      query: ({ body, payrollID }) => ({
        url: `/payroll/payroll-run/${payrollID}/payslip`,
        method: 'POST',
        body,
      }),
    }),
    getOneTimePaymentList: builder.query<any, any>({
      query: params => ({
        url: `/payroll/one-time-payments`,
        params,
      }),
      providesTags: ['oneTimePaymentList'],
    }),
    createOneTimePayment: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: '/payroll/one-time-payments',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['oneTimePaymentList'],
    }),
    deletePayrollRun: builder.mutation<any, any>({
      query: id => ({
        url: `/payroll/payroll-run/${id}/delete-payroll-run`,
        method: 'DELETE',
      }),
      invalidatesTags: ['payrollRunList'],
    }),
    postPayslipCompiled: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/payroll/payroll-run/${id}/payslip`,
        body,
        method: 'POST',
      }),
    }),
    getPayslipCompiled: builder.query<
      {
        fileName: string | null;
        fileUrl: string | null;
        mimeType: string | null;
        name: string | null;
      },
      any
    >({
      query: ({ id }) => ({
        url: `/payroll/payroll-run/${id}/payslip`,
      }),
    }),
    payrollRunPaymentDetailInfo: builder.query<
      any,
      { id: string; employeeId: string }
    >({
      query: ({ id, employeeId }) => ({
        url: `/payroll/payroll-run/${id}/review/payment-detail/${employeeId}/info`,
      }),
    }),
  }),
});

export const {
  useSubmitStepSetupMutation,
  useGetPayrollRunListQuery,
  useGetDetailStatusQuery,
  useGetApprovalsQuery,
  useUpdateDetailStatusMutation,
  useGetPayItemStepSetupQuery,
  useGetPayrollStepManageEmployeesQuery,
  useGetPayrollStepManageSeverityCountQuery,
  useGetPayrollPaymentDetailQuery,
  useGetPayrollStepReviewEmployeesQuery,
  useGetPayrollStepReviewGrossPayQuery,
  useGetPayrollRunSummaryQuery,
  useGetPayrollRunEmployeePaymentsTabQuery,
  useGetPayrollRunExpenseBreakdownQuery,
  useGetPayrollRunStepSetupHeaderQuery,
  useCancelDetailStatusMutation,
  useGetPaymentDetailSummaryQuery,
  usePayrollStepManageAddEmployeeMutation,
  usePayrollStepManageDeleteEmployeeMutation,
  usePayrollRunExportTransactionDetailMatrixQuery,
  usePayrollRunExportPayslipForEmployeerQuery,
  useGetCutOffQuery,
  usePayrollStepSetupAddPayItemMutation,
  usePayrollStepSetupRemovePayItemMutation,
  useGetPaymentInfoQuery,
  useCreatePaymentMutation,
  useRecalculatePayrollMutation,
  useRollbackPayrollRunMutation,
  useReopenPayrollRunMutation,
  useValidatePasswordMutation,
  useGetWorkflowFindDetailQuery,
  useCreateWorkflowOverviewMutation,
  useCompilePayrollEmployeeMutation,
  useGetOneTimePaymentListQuery,
  useCreateOneTimePaymentMutation,
  useDeletePayrollRunMutation,
  useGetPayslipCompiledQuery,
  usePostPayslipCompiledMutation,
  usePayrollRunPaymentDetailInfoQuery,
  usePayrollSyncPaygroupMutation,
} = PayrollRunApiSlice;
