import { WorkScheduleShiftInfoResponse } from '../../../@types/WorkScheduleTypes';
import { apiSlice } from '../api/apiSlice';

export const workScheduleApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    listWorkSchedule: builder.query<any, any>({
      query: params => {
        const {
          search,
          page,
          sortType,
          sortBy,
          limit,
          searchString,
          startDate,
          endDate,
        } = params;
        return {
          url: '/time-management/employee/work-schedule',
          params: {
            search,
            page,
            sortType,
            sortBy,
            limit,
            startDate,
            endDate,
            ...searchString,
          },
        };
      },
      providesTags: ['listWorkSchedule'],
    }),
    workScheduleShiftDetail: builder.query<
      WorkScheduleShiftInfoResponse,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/time-management/employee/work-schedule/${id}`,
      }),
      //   providesTags: ['detailTimeOff'],
    }),
    addShiftWorkSchedule: builder.mutation<any, any>({
      query: ({ employeeId, payload }) => ({
        url: `/time-management/employee/${employeeId}/work-schedule`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['listWorkSchedule'],
    }),
    assignScheduleEmployee: builder.mutation<any, any>({
      query: payload => ({
        url: `/time-management/employee/work-schedule`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['listWorkSchedule'],
    }),
    deleteShiftWorkSchedule: builder.mutation<any, any>({
      query: id => ({
        url: `/time-management/employee/work-schedule/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['listWorkSchedule'],
    }),
    bulkDeleteSchedule: builder.mutation<any, any>({
      query: payload => ({
        url: '/time-management/employee/work-schedule/bulk-delete',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['listWorkSchedule'],
    }),
  }),
});

export const {
  useListWorkScheduleQuery,
  useAddShiftWorkScheduleMutation,
  useWorkScheduleShiftDetailQuery,
  useAssignScheduleEmployeeMutation,
  useDeleteShiftWorkScheduleMutation,
  useBulkDeleteScheduleMutation,
} = workScheduleApiSlice;
