import { apiSlice } from '../../../api/apiSlice';
import { EmployeeWorkflowDetail } from '../../../../../@types/EmployeeWorkflow';

export const workflowAttendanceApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailWorkflowAttendance: builder.query<EmployeeWorkflowDetail, any>({
      query: args => {
        const { employeeId, ...query } = args;
        return {
          url: `/employee/employees/${employeeId}/workflow/attendance`,
          params: query,
        };
      },
      providesTags: ['employeeAttendanceWorkflow'],
    }),
    createAttendanceWorkflow: builder.mutation<
      void,
      { id: string; payload: any }
    >({
      query: ({ id, payload }) => ({
        url: `/employee/employees/${id}/workflow/attendance`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['employeeAttendanceWorkflow'],
    }),
    updateAttendanceWorkflow: builder.mutation<
      void,
      { id: string; payload: any }
    >({
      query: ({ id, payload }) => ({
        url: `/employee/employees/${id}/workflow/attendance`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['employeeAttendanceWorkflow'],
    }),
    deleteAttendanceWorkflow: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/employee/employees/${id}/workflow/attendance`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeAttendanceWorkflow'],
    }),
  }),
});

export const {
  useGetDetailWorkflowAttendanceQuery,
  useCreateAttendanceWorkflowMutation,
  useUpdateAttendanceWorkflowMutation,
  useDeleteAttendanceWorkflowMutation,
} = workflowAttendanceApiSlice;
