import { lazy } from 'react';
import { RouteNode } from '../../../@types';

const List = lazy(() => import('../../../pages/timeOff/List'));
const Create = lazy(() => import('../../../pages/timeOff/Create'));
const Details = lazy(() => import('../../../pages/timeOff/Details'));
const Assign = lazy(() => import('../../../pages/timeOff/Assign'));

export const timeOff: RouteNode[] = [
  {
    label: 'Time Off',
    child: null,
    path: 'time-off',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'time-off/create',
    component: Create,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    child: null,
    path: 'time-off/assign/:id',
    component: Assign,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    child: null,
    path: 'time-off/detail/:id',
    component: Details,
    requiredPermissions: 'VIEW',
  },
];
