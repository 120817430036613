import React from 'react';
import {
  Alert as AlertBase,
  AlertTitle,
  Box,
  Typography,
  useTheme,
  Snackbar,
  SxProps,
  Slide,
  SlideProps,
} from '@mui/material';

import { Button } from '..';
import { AlertProps } from '../../../@types';
import { mergeDeep } from '../../../utils/deepMerge';
import {
  AlertCircle,
  AlertTriangle,
  CheckCircle,
  Close,
  InfoCircle,
} from '../icon';

type TransitionProps = Omit<SlideProps, 'direction'>;
function TransitionRight(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

const Toast = React.forwardRef((Props: AlertProps, ref: any) => {
  const { variant, message, title, sx, bottomButton, open, onClose, ...props } =
    Props;
  const { palette } = useTheme();

  const bgColor: { [key: string]: string } = {
    success: palette.green[50],
    info: palette.blue[50],
    warning: palette.orange[50],
    error: palette.red[50],
  };

  const strokeColor: { [key: string]: string } = {
    success: palette.green[50],
    info: palette.blue[50],
    warning: palette.orange[50],
    error: palette.red[50],
  };

  const style: SxProps = {
    background: bgColor[variant],
    borderRadius: '8px',
    border: 'none',
    boxShadow:
      '0px 4px 8px -2px rgba(16, 27, 40, 0.1), 0px 2px 4px -2px rgba(16, 27, 40, 0.06)',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    '& .toast-container': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      padding: '14px',
      background: '#fff',
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
      maxWidth: '320px',
      '& .MuiButtonBase-root': {
        padding: 0,
        width: '20px',
        height: '20px',
        minWidth: '20px',
        '& svg': {
          transform: 'scale(0.8333)',
          '& path': {
            stroke: palette.gray[80],
          },
        },
      },
      '& svg': {
        minWidth: '20px !important',
        minHeight: '20px',
        alignSelf: 'flex-start',
        transform: 'scale(0.8333)',
        '& path': {
          stroke: strokeColor[variant],
        },
      },
    },
    '& .MuiAlert-message': {
      padding: 0,
    },
    '& .alert-title': {
      marginTop: 0,
      marginBottom: '0.5rem',
    },
    '& .MuiAlert-icon': {
      margin: 0,
    },
    '& .MuiAlert-action': {
      padding: 0,
      margin: 0,
      background: 'red',
      '& .MuiButtonBase-root': {
        padding: 0,
        width: '0px',
        height: '0px',
      },
    },
    '& .message-container': {
      display: 'flex',
      gap: '8px',
      flexDirection: 'column',
    },
  };

  return (
    <Snackbar
      ClickAwayListenerProps={{ onClickAway: () => null }}
      open={open}
      autoHideDuration={2500}
      onClose={(e: any) => {
        onClose?.(e);
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={TransitionRight}
    >
      <AlertBase
        {...props}
        onClose={onClose}
        ref={ref}
        sx={mergeDeep(style, sx)}
        components={{
          CloseIcon: () => <Box />,
        }}
        severity={variant}
        iconMapping={{
          warning: (
            <Box
              sx={{
                width: '8px',
                height: '100%',
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
              }}
            />
          ),
          error: (
            <Box
              sx={{
                width: '8px',
                height: '100%',
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
              }}
            />
          ),
          info: (
            <Box
              sx={{
                width: '8px',
                height: '100%',
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
              }}
            />
          ),
          success: (
            <Box
              sx={{
                width: '8px',
                height: '100%',
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
              }}
            />
          ),
        }}
      >
        <Box className="toast-container">
          {variant === 'error' && <AlertCircle />}
          {variant === 'success' && <CheckCircle />}
          {variant === 'info' && <InfoCircle />}
          {variant === 'warning' && <AlertTriangle />}
          {title && (
            <AlertTitle className="alert-title">
              <Typography
                variant="body16"
                fontWeight={600}
                color={palette.gray[100]}
              >
                {title}
              </Typography>
            </AlertTitle>
          )}
          <Box className="message-container">
            <Typography
              component="div"
              variant="body14"
              color={palette.gray[100]}
            >
              {message}
            </Typography>
          </Box>
          {onClose && (
            <Button onClick={onClose} variant="tertiary">
              <Close />
            </Button>
          )}
        </Box>
      </AlertBase>
    </Snackbar>
  );
});

export default Toast;
