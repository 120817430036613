import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createLogger } from 'redux-logger';
import { isDevelopment } from '../utils/env';
import { authReducer } from './feature/authSlice';
import { employeeReducer } from './feature/employeeSlice';
import { credentialReducer } from './feature/credentialSlice';
import { apiSlice } from './feature/api/apiSlice';
import { authApiSlice } from './feature/service/authApiSlice';
import { employeeApiSlice } from './feature/service/employeeApislice';
import { utilsReducer } from './feature/utilSlice';
import { employeeTabReducer } from './feature/empoyeeTabSlice';
import { organizationUnitReducer } from './feature/organizationUnitSlice';
import { organizationStructureReducer } from './feature/organizationStructureCreateSlice';
import { organizationStructureUpdateReducer } from './feature/organizationStructureUpdateSlice';
import { payGroupReducer } from './feature/payGroupSlice';
import { legalEntityReducer } from './feature/legalEntitySlice';
import { valueRoundingReducer } from './feature/valueRoundingSlice';
import { jobGradeReducer } from './feature/jobGrade';
import { employmentTypeReducer } from './feature/employmentType';
import { jobPositionReducer } from './feature/jobPosition';
import { payItemReducer } from './feature/payItemSlice';
import { customFieldApiSlice } from './feature/service/customFieldApiSlice';
import { customFieldReducer } from './feature/customFieldSlice';
import { exchangeRateReducer } from './feature/exchangeRateSlice';
import { workLocationReducer } from './feature/workLocationSlice';
import { costCenterApiSlice } from './feature/service/costCenterApiSlice';
import { costCenterReducer } from './feature/costCenterSlice';
import { accumulatorReducer } from './feature/accumulatorSlice';
import { employeeEarningReducer } from './feature/employeeEarningSlice';
import { employeeDeductionReducer } from './feature/employeeDeductionSlice';
import { userAccessReducer } from './feature/userAccessSlice';
import { compenstationReducer } from './feature/compensationSlice';
import { formulaReducer } from './feature/formula';
import { dynamicGroupReducer } from './feature/dynamicGroupSlice';
import { workflowSliceReducer } from './feature/workflowSlice';
import { employeePaymentDetailsReducer } from './feature/employeePaymentDetailsSlice';
import { payrollRunReducer } from './feature/payrollRunSlice';
import { AccessControllReducer } from './feature/accessControlSlice';
import { taxLocationReducer } from './feature/taxLocation';
import { chartOffAccountReducers } from './feature/chartOfAccuntSlice';
import { jobLevelReducer } from './feature/jobLevel';
import { loadOptionReducer } from './feature/loadOptions';
import { bankReducer } from './feature/bank';
import { terminationReducer } from './feature/terminationSlice';
import { payrollHistoryReducer } from './feature/payrollHistory';
import { reportGenerationReducer } from './feature/reportGeneration';
import { bankFileReducer } from './feature/bankFileSlice';
import { newsReducer } from './feature/newsSlice';
import { ConfigurationReducer } from './feature/service/report/configurationReport';
import { importReducer } from './feature/importSlice';
import { careerTransitionReducer } from './feature/careerTransitionSlice';
import { exportReducer } from './feature/exportSlice';
import { severancePayReducer } from './feature/severancePaySlice';
import { responsiveReducer } from './feature/responsiveSlice';
import { timeItemReducer } from './feature/timeItem';
import { timeEventReducer } from './feature/timeEventSlice';
import { attendanceStatusReducer } from './feature/attendanceStatusSlice';
import { shiftReducer } from './feature/shiftSlice';
import { attendanceProcessReducer } from './feature/attendanceProcessSlice';
import { scheduleReducer } from './feature/scheduleSlice';
import { timeOffReducer } from './feature/timeOffSlice';
import { payslipTemplateReducers } from './feature/payslipTemplateSlice';
import { homeReducer } from './feature/homeSlice';
import { workScheduleReducer } from './feature/workScheduleSlice';
import { attendanceMachineReducer } from './feature/attendanceMachineSlice';
import { dailyAttendanceReducer } from './feature/dailyAttendanceSlice';
import { holidayReducer } from './feature/holiday';
import { jobSiteReducer } from './feature/jobSiteSlice';
import { overtimeReducer } from './feature/overtimeSlice';
import { fileReducer } from './feature/fileSlice';
import { policyReducer } from './feature/policySlice';
import { overtimeItemReducer } from './feature/overtimeItemSlice';
import { changeLogoSliceReducer } from './feature/changeLogoSlice';
import { essAccessControlReducer } from './feature/essAccessControl';
import { timeOffItemReducer } from './feature/timeOffItemSlice';
import { essTimeOffReducer } from './feature/essTimeOffSlice';
import { claimProcessReducer } from './feature/claimProcessSlice';
import { employeeWorkflowReducer } from './feature/employeeWorkflow';

const logger = createLogger();
export const reducer = combineReducers({
  auth: authReducer,
  utils: utilsReducer,
  employeeTab: employeeTabReducer,
  employee: employeeReducer,
  credential: credentialReducer,
  organizationUnit: organizationUnitReducer,
  organizationStructure: organizationStructureReducer,
  payGroup: payGroupReducer,
  legalEntity: legalEntityReducer,
  organizationStructureUpdate: organizationStructureUpdateReducer,
  valueRounding: valueRoundingReducer,
  jobGrade: jobGradeReducer,
  customeField: customFieldReducer,
  exchangeRate: exchangeRateReducer,
  workLocation: workLocationReducer,
  employmentType: employmentTypeReducer,
  jobPosition: jobPositionReducer,
  payItem: payItemReducer,
  costCenter: costCenterReducer,
  accumulator: accumulatorReducer,
  employeeEarning: employeeEarningReducer,
  employeeDeduction: employeeDeductionReducer,
  compensation: compenstationReducer,
  userAccess: userAccessReducer,
  formula: formulaReducer,
  dynamicGroup: dynamicGroupReducer,
  workflow: workflowSliceReducer,
  employeePaymentDetails: employeePaymentDetailsReducer,
  payrollRun: payrollRunReducer,
  accessControll: AccessControllReducer,
  taxLocation: taxLocationReducer,
  chartOfAccount: chartOffAccountReducers,
  jobLevel: jobLevelReducer,
  bank: bankReducer,
  loadOption: loadOptionReducer,
  termination: terminationReducer,
  payrollHistory: payrollHistoryReducer,
  reportGeneration: reportGenerationReducer,
  bankFile: bankFileReducer,
  newsFile: newsReducer,
  configuration: ConfigurationReducer,
  import: importReducer,
  export: exportReducer,
  careerTransition: careerTransitionReducer,
  severancePay: severancePayReducer,
  responsive: responsiveReducer,
  timeItem: timeItemReducer,
  timeEvent: timeEventReducer,
  attendanceStatus: attendanceStatusReducer,
  shift: shiftReducer,
  attendanceProcess: attendanceProcessReducer,
  schedule: scheduleReducer,
  timeOff: timeOffReducer,
  payslipTemplate: payslipTemplateReducers,
  home: homeReducer,
  workSchedule: workScheduleReducer,
  attendanceMachine: attendanceMachineReducer,
  dailyAttendance: dailyAttendanceReducer,
  holiday: holidayReducer,
  jobSite: jobSiteReducer,
  overtime: overtimeReducer,
  overtimeItem: overtimeItemReducer,
  file: fileReducer,
  changeLogo: changeLogoSliceReducer,
  policy: policyReducer,
  essAccessControl: essAccessControlReducer,
  timeOffItem: timeOffItemReducer,
  essTimeOff: essTimeOffReducer,
  claimProcess: claimProcessReducer,
  employeeWorkflow: employeeWorkflowReducer,
  [customFieldApiSlice.reducerPath]: customFieldApiSlice.reducer,
  [costCenterApiSlice.reducerPath]: costCenterApiSlice.reducer,
  [customFieldApiSlice.reducerPath]: customFieldApiSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [authApiSlice.reducerPath]: authApiSlice.reducer,
  [employeeApiSlice.reducerPath]: employeeApiSlice.reducer,
});

export type RootState = ReturnType<typeof reducer>;

export const createStore = () =>
  configureStore({
    reducer,
    middleware: getDefaultMiddleware => {
      if (isDevelopment()) {
        return getDefaultMiddleware().concat(apiSlice.middleware);
        // .concat(logger);
      }
      return getDefaultMiddleware().concat(apiSlice.middleware);
    },
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
