import { apiSlice } from '../../../api/apiSlice';
import { EmployeeWorkflowDetail } from '../../../../../@types/EmployeeWorkflow';

export const workflowDataUpdateApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailWorkflowDataUpdate: builder.query<EmployeeWorkflowDetail, any>({
      query: args => {
        const { employeeId, ...query } = args;
        return {
          url: `/employee/employees/${employeeId}/workflow/data-update-request`,
          params: query,
        };
      },
      providesTags: ['employeeDataUpdateWorkflow'],
    }),
    createDataUpdateWorkflow: builder.mutation<
      void,
      { id: string; payload: any }
    >({
      query: ({ id, payload }) => ({
        url: `/employee/employees/${id}/workflow/data-update-request`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['employeeDataUpdateWorkflow'],
    }),
    updateDataUpdateWorkflow: builder.mutation<
      void,
      { id: string; payload: any }
    >({
      query: ({ id, payload }) => ({
        url: `/employee/employees/${id}/workflow/data-update-request`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['employeeDataUpdateWorkflow'],
    }),
    deleteDataUpdateWorkflow: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/employee/employees/${id}/workflow/data-update-request`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeDataUpdateWorkflow'],
    }),
  }),
});

export const {
  useGetDetailWorkflowDataUpdateQuery,
  useCreateDataUpdateWorkflowMutation,
  useDeleteDataUpdateWorkflowMutation,
  useUpdateDataUpdateWorkflowMutation,
} = workflowDataUpdateApiSlice;
