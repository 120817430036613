import { lazy } from 'react';
import { RouteNode } from '../../../@types';

const List = lazy(() => import('../../../pages/workSchedule/List'));
const AssignSchedule = lazy(
  () => import('../../../pages/workSchedule/AssignSchedule')
);
const DeleteSchedule = lazy(() => import('../../../pages/workSchedule/Delete'));

export const workSchedule: RouteNode[] = [
  {
    label: 'Work Schedule',
    child: null,
    path: 'work-schedule',
    component: List,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    child: null,
    path: 'work-schedule/assign-schedule',
    component: AssignSchedule,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    child: null,
    path: 'work-schedule/delete-schedule',
    component: DeleteSchedule,
    requiredPermissions: 'DELETE',
  },
];
