import React from 'react';
import { RouteNode } from '../../@types';
import { Users03 } from '../../components/atom/icon';
import costCenter from './costCenter';
import bank from './bank';
import family from './family';
import file from './files';
import PersonalInfo from './PersonalInfo';
import education from './education';
import Address from './address';
import Statutory from './statutory';
import earning from './earning';
import deduction from './deduction';
import paymentDetails from './paymentDetails';
import careerTransition from './careerTransition';
import timeOff from './timeOff';
import employeeDetailClaimRoute from './claim';
import payrollInfo from './payrollInfo';
import previousEmp from './previousEmp';
import employeeWorkflow from './employeeWorkflow';

const Employee = React.lazy(() => import('../../pages/employee/List'));
const EmployeeCreate = React.lazy(() => import('../../pages/employee/Create'));
const EmployeeDetailWorkLocationCreate = React.lazy(
  () => import('../../pages/employee/WorkLocation/Create')
);
const EmployeeDetailWorkLocationUpdate = React.lazy(
  () => import('../../pages/employee/WorkLocation/Update')
);
const EmployeeDetailWorkLocationCorrect = React.lazy(
  () => import('../../pages/employee/WorkLocation/Correction')
);
const EmployeeDetail = React.lazy(() => import('../../pages/employee/Details'));
const ExportEmployee = React.lazy(
  () => import('../../pages/exportEmployee/ExportEmployee')
);
const EmployeeCorrection = React.lazy(
  () => import('../../pages/employee/PersonalCorrection')
);
const StatutoryCorrection = React.lazy(
  () => import('../../pages/employee/StatutoryCorrection')
);
const UpdateEmploymentData = React.lazy(
  () => import('../../pages/employee/Employment/Update')
);
const EmploymentDataCorrection = React.lazy(
  () => import('../../pages/employee/Employment/Correction')
);
const ExperienceCreate = React.lazy(
  () => import('../../pages/employee/experience/Create')
);
const ExperienceUpdate = React.lazy(
  () => import('../../pages/employee/experience/Update')
);
const ExperienceCorrection = React.lazy(
  () => import('../../pages/employee/experience/Correction')
);

export const EmployeeRoute: RouteNode[] = [
  {
    label: 'Employee',
    icon: Users03,
    path: 'employee',
    component: Employee,
    child: null,
    requiredPermissions: 'VIEW',
  },
  {
    label: '',
    path: 'employee/detail/:id',
    component: EmployeeDetail,
    child: null,
    requiredPermissions: 'VIEW',
  },

  {
    label: '',
    path: '/employee/detail/:id/work-location/create',
    component: EmployeeDetailWorkLocationCreate,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: '/employee/detail/:id/work-location/update',
    component: EmployeeDetailWorkLocationUpdate,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: '/employee/detail/:id/work-location/correction',
    component: EmployeeDetailWorkLocationCorrect,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
  {
    label: '',
    path: 'employee/create',
    component: EmployeeCreate,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/employment/update',
    component: UpdateEmploymentData,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'employee/detail/:id/employment/correction',
    component: EmploymentDataCorrection,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
  {
    label: '',
    path: 'employee/:id/experience-create',
    component: ExperienceCreate,
    child: null,
    requiredPermissions: 'CREATE',
  },
  {
    label: '',
    path: 'employee/:id/experience-update/:groupId',
    component: ExperienceUpdate,
    child: null,
    requiredPermissions: 'UPDATE',
  },
  {
    label: '',
    path: 'employee/:id/experience-correction/:groupId',
    component: ExperienceCorrection,
    child: null,
    requiredPermissions: 'CORRECTION',
  },
  ...costCenter,
  ...bank,
  ...family,
  ...file,
  ...PersonalInfo,
  ...education,
  ...Address,
  ...Statutory,
  ...earning,
  ...deduction,
  ...paymentDetails,
  ...careerTransition,
  ...timeOff,
  ...employeeDetailClaimRoute,
  ...payrollInfo,
  ...previousEmp,
  ...employeeWorkflow,
];
