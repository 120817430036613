import { apiSlice } from '../../../api/apiSlice';
import { EmployeeWorkflowDetail } from '../../../../../@types/EmployeeWorkflow';

export const workflowTimeOffApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDetailWorkflowTimeOff: builder.query<EmployeeWorkflowDetail, any>({
      query: args => {
        const { employeeId, ...query } = args;
        return {
          url: `/employee/employees/${employeeId}/workflow/time-off`,
          params: query,
        };
      },
      providesTags: ['employeeTimeOffWorkflow'],
    }),
    createTimeOffWorkflow: builder.mutation<void, { id: string; payload: any }>(
      {
        query: ({ id, payload }) => ({
          url: `/employee/employees/${id}/workflow/time-off`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['employeeTimeOffWorkflow'],
      }
    ),
    updateTimeOffWorkflow: builder.mutation<void, { id: string; payload: any }>(
      {
        query: ({ id, payload }) => ({
          url: `/employee/employees/${id}/workflow/time-off`,
          method: 'PUT',
          body: payload,
        }),
        invalidatesTags: ['employeeTimeOffWorkflow'],
      }
    ),
    deleteTimeOffWorkflow: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/employee/employees/${id}/workflow/time-off`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeTimeOffWorkflow'],
    }),
  }),
});

export const {
  useGetDetailWorkflowTimeOffQuery,
  useCreateTimeOffWorkflowMutation,
  useDeleteTimeOffWorkflowMutation,
  useUpdateTimeOffWorkflowMutation,
} = workflowTimeOffApiSlice;
