import { createSlice } from '@reduxjs/toolkit';
import { standardReportApiSlice } from './service/report/standardReport';
import { ConfigurationSlice } from './service/report/configurationReport';
import { HistorySlice } from './service/report/historyApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
  isLoading: boolean;
  dataDownload: any;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
  dataDownload: {},
  isLoading: false,
};

const reportGenerationSlice = createSlice({
  name: 'reportGeneration',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
    resetData: state => {
      state.dataDownload = null;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      standardReportApiSlice.endpoints.postGenerate1721i.matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({ ...action?.payload, report: 'Report: 1721 - I' })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.postGenerateBPJSKesehatan.matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: BPJS Kesehatan',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.postGeneratePayrollRegister
        .matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: Payroll Register',
            queryName: 'payrollRegister',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.postGenerateIncomeTaxCalculation
        .matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: Income Tax Calculation',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generate1721a1Report.matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: 1721 - A1',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generate1721a1Report.matchRejected,
      (state, action) => {
        action?.payload?.status === 404
          ? (state.messageError = 'Report Not Found')
          : (state.messageError = 'Internal Server Error');
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateBPJSKetenagakerjaan
        .matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: BPJS Ketenagakerjaan (Baru)',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateBPJSKetenagakerjaanLanjutan
        .matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: BPJS Ketenagakerjaan (Lanjutan)',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateCostCenterReport.matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: Cost Center',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateBupot2126.matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: e-Bupot 21/26',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateBupot2126.matchRejected,
      (state, action) => {
        state.messageError =
          action.payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateCostCenterReport.matchRejected,
      (state, action) => {
        console.log('ini action', action.payload);
        state.messageError = 'Report not found';
        // action.payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.postGenerate1721i.matchRejected,
      (state, action) => {
        state.messageError = action.payload?.data?.message;
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.postGenerateBPJSKesehatan.matchRejected,
      (state, action) => {
        state.messageError = action.payload?.data?.message;
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.postGenerateIncomeTaxCalculation
        .matchRejected,
      (state, action) => {
        state.messageError =
          action.payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.postGeneratePayrollRegister
        .matchRejected,
      (state, action) => {
        state.messageError =
          action.payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.postGenerate1721i.matchPending,
      (state, _action) => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      ConfigurationSlice.endpoints.updateConfiguration1721i.matchFulfilled,
      state => {
        state.messageSuccess = '1721 - I Report configured';
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.postGenerate1721i.matchPending,
      (state, _action) => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      ConfigurationSlice.endpoints.submitConfiguration1721A1.matchFulfilled,
      (state, action) => {
        state.messageSuccess = '1721 - A1 Report configured';
      }
    );
    builder.addMatcher(
      ConfigurationSlice.endpoints.submitConfiguration1721A1.matchRejected,
      (state, action) => {
        state.messageError =
          action.payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      ConfigurationSlice.endpoints.updateConfigurationBpjs.matchFulfilled,
      state => {
        state.messageSuccess = 'BPJS Kesehatan Report configured';
      }
    );
    builder.addMatcher(
      ConfigurationSlice.endpoints.submitConfigurationBpjsTkBaru.matchFulfilled,
      (state, action) => {
        state.messageSuccess = 'BPJS Ketenagakerjaan (Baru) Report configured';
      }
    );
    builder.addMatcher(
      ConfigurationSlice.endpoints.submitConfigurationBpjsTkBaru.matchRejected,
      (state, action) => {
        state.messageError =
          action.payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      ConfigurationSlice.endpoints.submitConfigurationBpjsTkLanjutan
        .matchFulfilled,
      (state, action) => {
        state.messageSuccess =
          'BPJS Ketenagakerjaan (Lanjutan) Report configured';
      }
    );
    builder.addMatcher(
      ConfigurationSlice.endpoints.submitConfigurationBpjsTkLanjutan
        .matchRejected,
      (state, action) => {
        state.messageError =
          action.payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      HistorySlice.endpoints.useDeleteHistory1721A1.matchFulfilled,
      state => {
        state.messageSuccess = 'History deleted';
      }
    );
    builder.addMatcher(
      HistorySlice.endpoints.deleteHistoryBupot2126.matchFulfilled,
      state => {
        state.messageSuccess = 'History deleted';
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateOvertimeReport.matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: Overtime',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateAttendanceProcessReport
        .matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: Attendance Process',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateAttendanceProcessReport
        .matchRejected,
      (state, action) => {
        state.messageError =
          action.payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateAttendanceReport.matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: Attendance',
            queryName: 'attendance',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateAttendanceReport.matchRejected,
      (state, action) => {
        state.messageError =
          action.payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateTimeOffReport.matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: Time Off',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateTimeOffReport.matchRejected,
      (state, action) => {
        state.messageError =
          action.payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateTimeOffBalanceReport
        .matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: Time Off Balance',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateTimeOffBalanceReport
        .matchRejected,
      (state, action) => {
        state.messageError =
          action.payload?.data?.message || 'Internal Server Error';
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateOvertimeRequestReport
        .matchFulfilled,
      (state, action) => {
        localStorage.setItem(
          'dataDownload',
          JSON.stringify({
            ...action?.payload,
            report: 'Report: Overtime Request',
          })
        );
      }
    );
    builder.addMatcher(
      standardReportApiSlice.endpoints.generateOvertimeRequestReport
        .matchRejected,
      (state, action) => {
        state.messageError =
          action.payload?.data?.message || 'Internal Server Error';
      }
    );
  },
});

export const { removeMessage, resetData } = reportGenerationSlice.actions;
export const reportGenerationReducer = reportGenerationSlice.reducer;
